import React from 'react';
import HelmetName from '../components/HelmetName';

const Scouting = () => {
  return (
    <div>
      <HelmetName title="Scouting - Logan B. Anthony" description="Dive into scouting reports and player evaluations." />
      <h1>Scouting</h1>
      <p>Dive into scouting reports and player evaluations.</p>
    </div>
  );
};

export default Scouting;
