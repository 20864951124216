import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../logo.jpeg'; // Assuming your logo is in the src folder

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let navbarClasses = ['navbar'];
  if (scrolled) {
    navbarClasses.push('scrolled');
  }

  return (
    <nav className={navbarClasses.join(' ')}>
      <div className="navbar-left">
        {!scrolled && (
          <Link to="/" className="navbar-logo-link">
            <img src={logo} alt="Logo" className="navbar-logo" />
          </Link>
        )}
        <Link to="/" className="navbar-name-link">
          <span className="navbar-name">Logan B. Anthony</span>
        </Link>
      </div>
      <ul className="navbar-list">
        <li className="navbar-item">
          <Link to="/" className="navbar-link">Home</Link>
        </li>
        <li className="navbar-item dropdown">
          <span className="navbar-link">Baseball</span>
          <ul className="dropdown-content">
            <li><Link to="/research" className="dropdown-link">Research</Link></li>
            <li><Link to="/scouting" className="dropdown-link">Scouting</Link></li>
          </ul>
        </li>
        <li className="navbar-item">
          <a href="https://github.com/logananthony" 
             className="navbar-link"
             target="_blank" 
             rel="noopener noreferrer">
            Github
          </a>
        </li>
        <li className="navbar-item">
          <a href="https://x.com/loganbanthony" 
             className="navbar-link"
             target="_blank" 
             rel="noopener noreferrer">
            X
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
