import React from 'react';
import HelmetName from '../components/HelmetName';

const Home = () => (
  <div>
    <HelmetName title="Home - Logan B. Anthony" description="Welcome, a blog about baseball research and scouting." />
    <h1>Welcome!</h1>
    {/* Other content */}
  </div>
);

export default Home;
