import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Research from './pages/Research';
import Scouting from './pages/Scouting';
import SequencingPlusArticle from './pages/SequencingPlusPt1';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/research" element={<Research />} />
        <Route path="/research/creating-sequencing-plus-pt-1" element={<SequencingPlusArticle />} /> {/* Updated this line */}
        <Route path="/scouting" element={<Scouting />} />
      </Routes>
    </Router>
  );
}

export default App;
