import React from 'react';
import HelmetName from '../components/HelmetName';
import { Link } from 'react-router-dom';
import './Research.css';

const Research = () => {
  const articles = [
    {
      title: 'Creating Sequencing+ (Part 1: Pitch Sequence Run Values)',
      summary: 'A new metric to capture pitch sequencing skill.',
      link: '/research/creating-sequencing-plus-pt-1'
    },
  ];

  return (
    <div className="research-container">
      <HelmetName title="Research - Logan B. Anthony" description="Explore in-depth analysis and research articles." />
      <h1 className="research-title">Research</h1>
      <p className="research-intro">Explore in-depth analysis and research articles.</p>
      <div className="articles-grid">
        {articles.map((article, index) => (
          <Link to={article.link} key={index} className="article-link"> {/* Wrap the entire card in a Link */}
            <div className="article-card">
              <h2 className="article-title">{article.title}</h2>
              <p className="article-summary">{article.summary}</p>
              <p className="read-more">Read more</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Research;
